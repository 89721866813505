import { ArrowDown, ArrowUp } from 'phosphor-react';

import { theme } from 'styled/theme';

import { Box } from 'components/Box';

import { DESC } from '../constants';

import type { FC } from 'react';
import type { Color } from 'styled/types';
import type { SortDirection } from '../types';

export const SortIcon: FC<{ direction: SortDirection; color: Color }> = ({ direction, color }) => (
  <Box maxWidth="10px" marginBottom={4} paddingLeft={2} paddingRight={2}>
    {direction === DESC ? (
      <ArrowUp weight="bold" color={theme.colors[color]} />
    ) : (
      <ArrowDown weight="bold" color={theme.colors[color]} />
    )}
  </Box>
);
