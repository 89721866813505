import type { SupportedMarket } from './types';

export const supportedMarkets: SupportedMarket[] = [
  {
    marketCountry: 'Australia',
    marketName: 'Australian Securities Exchange',
    type: 'Stock Exchange',
    code: 'ASX',
    priceDelay: '20 minutes',
  },
  {
    marketCountry: 'Australia',
    marketName: 'Australia Managed Funds',
    type: 'Managed Fund',
    code: 'FundAU',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'Australia',
    marketName: 'Cboe Australia',
    type: 'Stock Exchange',
    code: 'CXA',
    priceDelay: '20 minutes',
  },
  {
    marketCountry: 'Australia',
    marketName: 'Managed Funds',
    type: 'Managed Fund',
    code: 'mFund',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'Austria',
    marketName: 'Vienna Stock Exchange',
    type: 'Stock Exchange',
    code: 'VIE',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'Belgium',
    marketName: 'Euronext Brussels',
    type: 'Stock Exchange',
    code: 'EURONEXT',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Brazil',
    marketName: 'B3 BOVESPA',
    type: 'Stock Exchange',
    code: 'BVMF',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Canada',
    marketName: 'Canadian Securities Exchange',
    type: 'Stock Exchange',
    code: 'CNSX',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Canada',
    marketName: 'Toronto TSX Ventures Exchange',
    type: 'Stock Exchange',
    code: 'CVE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Canada',
    marketName: 'Neo Exchange',
    type: 'Stock Exchange',
    code: 'NEO',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Canada',
    marketName: 'Toronto Stock Exchange',
    type: 'Stock Exchange',
    code: 'TSE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Canada',
    marketName: 'Canadian Mutual Funds',
    type: 'Managed Fund',
    code: 'FundCA',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'China',
    marketName: 'Hong Kong Stock Exchange',
    type: 'Stock Exchange',
    code: 'HKG',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'China',
    marketName: 'Shenzhen Stock Exchange',
    type: 'Stock Exchange',
    code: 'SHE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'China',
    marketName: 'Shanghai Stock Exchange',
    type: 'Stock Exchange',
    code: 'SHG',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Czech Republic',
    marketName: 'Prague Stock Exchange',
    type: 'Stock Exchange',
    code: 'PSE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Denmark',
    marketName: 'Nasdaq Nordic Copenhagen',
    type: 'Stock Exchange',
    code: 'CSE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Finland',
    marketName: 'Nasdaq Nordic Helsinki',
    type: 'Stock Exchange',
    code: 'HEL',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'France',
    marketName: 'Euronext Paris',
    type: 'Stock Exchange',
    code: 'EURONEXT',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Germany',
    marketName: 'Deutsche Börse Frankfurt Stock Exchange',
    type: 'Stock Exchange',
    code: 'FRA',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Germany',
    marketName: 'Deutsche Börse Xetra',
    type: 'Stock Exchange',
    code: 'XETR',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Greece',
    marketName: 'Athens Stock Exchange',
    type: 'Stock Exchange',
    code: 'ASE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Hungary',
    marketName: 'Budapest Stock Exchange',
    type: 'Stock Exchange',
    code: 'BDP',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Iceland',
    marketName: 'Nasdaq Nordic Iceland',
    type: 'Stock Exchange',
    code: 'ICE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'India',
    marketName: 'Bombay Stock Exchange Limited',
    type: 'Stock Exchange',
    code: 'BSE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'India',
    marketName: 'National Stock Exchange of India',
    type: 'Stock Exchange',
    code: 'NSE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Indonesia',
    marketName: 'Indonesia Stock Exchange',
    type: 'Stock Exchange',
    code: 'IDX',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Ireland',
    marketName: 'Irish Funds',
    type: 'Managed Fund',
    code: 'FundIE',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'Ireland',
    marketName: 'Euronext Dublin',
    type: 'Stock Exchange',
    code: 'DUB',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Israel',
    marketName: 'Tel Aviv Stock Exchange',
    type: 'Stock Exchange',
    code: 'TLV',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Italy',
    marketName: 'Borsa Italiana Milan Stock Exchange',
    type: 'Stock Exchange',
    code: 'BIT',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Japan',
    marketName: 'Tokyo Stock Exchange',
    type: 'Stock Exchange',
    code: 'TYO',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Luxembourg',
    marketName: 'Luxembourg Managed Funds',
    type: 'Managed Fund',
    code: 'FundLU',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Malaysia',
    marketName: 'Bursa Malaysia Stock Exchange',
    type: 'Stock Exchange',
    code: 'KLS',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Mexico',
    marketName: 'Bolsa Mexicana de Valores',
    type: 'Stock Exchange',
    code: 'BMV',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Netherlands',
    marketName: 'Euronext Amsterdam',
    type: 'Stock Exchange',
    code: 'EURONEXT',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'New Zealand',
    marketName: 'New Zealand Stock Exchange',
    type: 'Stock Exchange',
    code: 'NZX',
    priceDelay: '20 minutes',
  },
  {
    marketCountry: 'New Zealand',
    marketName: 'New Zealand Managed Funds',
    type: 'Managed Fund',
    code: 'FundNZ',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'Norway',
    marketName: 'Oslo Stock Exchange',
    type: 'Stock Exchange',
    code: 'OSL',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Peru',
    marketName: 'Bolsa de Valores de Lima',
    type: 'Stock Exchange',
    code: 'BVL',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Philippines',
    marketName: 'Philippine Stock Exchange',
    type: 'Stock Exchange',
    code: 'PHS',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Poland',
    marketName: 'Warsaw Stock Exchange',
    type: 'Stock Exchange',
    code: 'WAR',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Portugal',
    marketName: 'Euronext Lisbon',
    type: 'Stock Exchange',
    code: 'EURONEXT',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Romania',
    marketName: 'Bucharest Stock Exchange',
    type: 'Stock Exchange',
    code: 'BVB',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Russia',
    marketName: 'Moscow Exchange',
    type: 'Stock Exchange',
    code: 'MISX',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Singapore',
    marketName: 'Singapore Exchange',
    type: 'Stock Exchange',
    code: 'SGX',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'South Africa',
    marketName: 'Johannesburg Stock Exchange',
    type: 'Stock Exchange',
    code: 'JSE',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'South Korea',
    marketName: 'Korean Securities Dealers Automated Quotations',
    type: 'Stock Exchange',
    code: 'KOSDAQ',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'South Korea',
    marketName: 'Korea Exchange',
    type: 'Stock Exchange',
    code: 'KRX',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Spain',
    marketName: 'Bolsas y Mercados Españoles',
    type: 'Stock Exchange',
    code: 'BME',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Sweden',
    marketName: 'Nasdaq Nordic Stockholm',
    type: 'Stock Exchange',
    code: 'STO',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Switzerland',
    marketName: 'SIX Swiss Exchange',
    type: 'Stock Exchange',
    code: 'SWX',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Taiwan',
    marketName: 'Taiwan Stock Exchange',
    type: 'Stock Exchange',
    code: 'TAI',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Thailand',
    marketName: 'Stock Exchange of Thailand',
    type: 'Stock Exchange',
    code: 'BKK',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'Turkey',
    marketName: 'Borsa Istanbul',
    type: 'Stock Exchange',
    code: 'BIST',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'United Kingdom',
    marketName: 'London Stock Exchange',
    type: 'Stock Exchange',
    code: 'LSE',
    priceDelay: '15 minutes',
  },
  {
    marketCountry: 'United Kingdom',
    marketName: 'UK Mutual Funds',
    type: 'Managed Fund',
    code: 'FundUK',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'United Kingdom',
    marketName: 'UK Life and Pension Funds',
    type: 'Managed Fund',
    code: 'FundUK',
    priceDelay: 'Daily',
  },
  {
    marketCountry: 'United States',
    marketName: 'NYSE American',
    type: 'Stock Exchange',
    code: 'AMEX',
    priceDelay: '15 minutes',
  },
  {
    marketCountry: 'United States',
    marketName: 'BATS Exchange',
    type: 'Stock Exchange',
    code: 'BATS',
    priceDelay: '15 minutes',
  },
  {
    marketCountry: 'United States',
    marketName: 'NASDAQ Stock Exchange',
    type: 'Stock Exchange',
    code: 'NASDAQ',
    priceDelay: '15 minutes',
  },
  {
    marketCountry: 'United States',
    marketName: 'The New York Stock Exchange',
    type: 'Stock Exchange',
    code: 'NYSE',
    priceDelay: '15 minutes',
  },
  {
    marketCountry: 'United States',
    marketName: 'NYSE ARCA',
    type: 'Stock Exchange',
    code: 'NYSE',
    priceDelay: '15 minutes',
  },
  {
    marketCountry: 'United States',
    marketName: 'Other OTC',
    type: 'Stock Exchange',
    code: 'OTC',
    priceDelay: 'End of day',
  },
  {
    marketCountry: 'United States',
    marketName: 'US Mutual Funds',
    type: 'Managed Fund',
    code: 'FundUS',
    priceDelay: 'Daily',
  },
];
