import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Box } from 'components/Box';
import { Container, Section } from 'components/Container';
import { AnimatedContainer } from 'components/Container/AnimatedContainer';
import { SupportedMarketsTable } from 'components/Table/SupportedMarketsTable';

import { HelpLink } from 'components/Links';

import { useContentfulLocale } from 'hooks/useContentfulLocale';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const SupportedMarkets: FC<LocalizedPageProps> = () => {
  const {
    translations: { supportedMarkets, trackedInvestments },
  } = useContentfulLocale();

  return (
    <Layout>
      <Seo />
      <AnimatedContainer animatedBackground="supportedMarkets" padding={15}>
        <Section>
          <h1>Supported stock exchanges & funds</h1>
          <p>
            Automatically track your investments listed on the below stock exchanges and managed or
            mutual funds.
          </p>
        </Section>
      </AnimatedContainer>
      <Container manageLayout={false} background="white">
        <Box paddingBottom={8}>
          <p>
            Sharesight supports over {supportedMarkets} stock exchanges and managed funds.
            Automatically track price, performance and dividends from {trackedInvestments}+ global
            stocks, crypto, ETFs and funds. If a market you invest in is not listed below, you can
            add your holding as a <HelpLink to="unquoted-instruments">custom investment</HelpLink>.
          </p>
        </Box>
        <SupportedMarketsTable />
      </Container>
    </Layout>
  );
};

export default SupportedMarkets;
