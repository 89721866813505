import { FlexContainer } from 'components/FlexContainer';

import { TableCellUnderline } from '../styled/TableCellUnderline.styled';
import { TableHeader } from '../styled/TableHeader.styled';

import { SortIcon } from './SortIcon';
import { TableCell } from './TableCell';

import type { FC, ReactNode } from 'react';
import type { SortDirection } from '../types';
import type { Color } from 'styled/types';

interface SortableTableCellProps {
  id: string;
  title: ReactNode;
  width?: number;
  sortable?: boolean;
  handleOnClick?: (columnKey: string) => void;
  columnSortDirection?: { [key: string]: SortDirection };
  selectedColumn?: string;
}

export const SortableTableCell: FC<SortableTableCellProps> = ({
  id,
  title,
  width,
  sortable,
  handleOnClick,
  columnSortDirection,
  selectedColumn,
}) => {
  const isSelectedColumn = selectedColumn === id;
  const iconDirection =
    sortable && !!columnSortDirection && !!columnSortDirection[id]
      ? columnSortDirection[id]
      : 'ascending'; // default icon direction
  const color: Color = isSelectedColumn ? 'orange' : 'grey';

  return (
    <TableHeader key={id}>
      <TableCell
        width={width}
        as="div"
        handleOnClick={sortable ? handleOnClick : undefined}
        columnId={id}
      >
        <FlexContainer justifyContent="center" alignItems="center">
          {title}
          {sortable && iconDirection && <SortIcon direction={iconDirection} color={color} />}
        </FlexContainer>
      </TableCell>
      <TableCellUnderline selected={isSelectedColumn} />
    </TableHeader>
  );
};
